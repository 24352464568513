import React, { useState } from 'react';
import './admin.css';

const RegisterPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');

    const registerUser = () => {
        const registrationData = {
            action: 'register',
            newUsername: username,
            newPassword: password,
            role: role
        };

        fetch('Register.php', {
            method: 'POST',
            body: JSON.stringify(registrationData),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            if (data.message === 'Registration successful') {
                alert('Registration successful. You can now log in.');
            } else {
                alert('Registration failed. Please try again.');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const logout = () => {
        alert('You have been logged out.');
        window.location.href = 'https://re-prototype.com';
    };

    return (
        <div>
            <div className="admin-header">
                <button className="admin-logout-button" onClick={logout}>Logout</button>
            </div>
            <div className="admin-container">
                <h1 className="admin-title">Welcome to the Admin Dashboard</h1>
                <div>
                    <h2 className="admin-subtitle">Register New User</h2>
                    <form className="admin-form">
                        <label className="admin-label" htmlFor="new-username">New Username:</label>
                        <input className="admin-input" type="text" id="new-username" name="new-username" required onChange={(e) => setUsername(e.target.value)} />
                        <br />
                        <label className="admin-label" htmlFor="new-password">New Password:</label>
                        <input className="admin-input" type="password" id="new-password" name="new-password" required onChange={(e) => setPassword(e.target.value)} />
                        <br />
                        <label className="admin-label" htmlFor="user-role">Select Role:</label>
                        <select className="admin-select" id="user-role" name="user-role" onChange={(e) => setRole(e.target.value)}>
                            <option value="1">Admin</option>
                            <option value="2">Owner</option>
                            <option value="3">Property Manager</option>
                            <option value="4">User</option>
                        </select>
                        <br />
                        <button className="admin-button" type="button" onClick={registerUser}>
                            Register
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;