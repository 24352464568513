import React, { useState } from 'react';
import './General.css';

const MenuItem = ({ label, onClick }) => (
  <div className="user-menu-item" onClick={onClick}>
    {label}
  </div>
);

const User = () => {
  const [userId, setUserId] = useState(1);
  const [output, setOutput] = useState('');

  const handleItemClick = (item) => {
    console.log(item);
    setOutput(`Output for ${item}`);
  };

  const renderSection = (title, range) => {
    if (userId === 1) {
      return (
        <div>
          <h2>{title}</h2>
          {range.map((func) => (
            <MenuItem key={func} label={`Functie ${func}`} onClick={() => handleItemClick(`Functie ${func}`)} />
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <header className="user-header">
        <h1>Vastgoed Menu</h1>
        <button className="user-logout-button" onClick={() => console.log('Logout')}>
          Logout
        </button>
      </header>

      <div className="user-container">
        <div className="user-content-container">
          <div className="user-sidebar">
            {renderSection('Menu', [1, 2, 3, 4, 5, 6])}
            {renderSection('Assets', [1, 2, 3, 4, 5, 6])}
          </div>

          <div className="user-content">
            <h2>Content div</h2>
            <p>Test</p>
            <p>{output}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
