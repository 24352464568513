import React, { useState } from 'react';
import './General.css'; // Import the user.css file for styling

const MenuItem = ({ label, onClick }) => (
  <div className="user-menu-item" onClick={onClick}>
    {label}
  </div>
);

const Owner = () => {
  const [userId, setUserId] = useState(2);
  const [output, setOutput] = useState('');

  const handleItemClick = (item) => {
    console.log(item);
    setOutput(`Output for ${item}`);
  };

  const handleLogout = () => {
    console.log('Logging out...');
    window.location.href = 'https://re-prototype.com';
  };

  const renderSection = (title, buttons) => {
    if (userId === 2) {
      return (
        <div>
          <h2>{title}</h2>
          {buttons.map((button) => (
            <MenuItem key={button} label={button} onClick={() => handleItemClick(button)} />
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-container"> {/* Use the user-container class */}
      <header className="user-header"> {/* Use the user-header class */}
        <h1>Owner Menu</h1>
        <button className="user-logout-button" onClick={handleLogout}>
          Logout
        </button>
      </header>

      <div className="user-content-container"> {/* Use the user-content-container class */}
        <div className="user-sidebar"> {/* Use the user-sidebar class */}
          {renderSection('Portfolio', ['Properties', 'Contracts', 'Asset manager', 'Service Provider', 'Tenant', 'Contact'])}
          {renderSection('Contracts', ['Property Contract', 'Rental Contracts', 'Service Contract', 'Units', 'Tenant entity', 'Contact persons'])}
        </div>
        <div className="user-content"> {/* Use the user-content class */}
          <h2>Content div</h2>
          <p>Test</p>
          <p>{output}</p>
        </div>
      </div>
    </div>
  );
};

export default Owner;