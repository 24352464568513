// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import User from './User';
import Owner from './Owner';
import PropertyManager from './PropertyManager';
import Admin from './Admin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/owner" element={<Owner />} />
        <Route path="/property-manager" element={<PropertyManager />} />
        <Route path="/user" element={<User />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;