import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const loginData = {
                username,
                password,
            };

            const response = await fetch('Login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            const data = await response.json();
            console.log('Data:', data);

            if (data.message === 'Login successful') {
                switch (data.role) {
                    case 1: // Admin
                        navigate('/admin');
                        break;
                    case 2: // Owner
                        navigate('/owner');
                        break;
                    case 3: // Property Manager
                        navigate('/property-manager');
                        break;
                    case 4: // User
                        navigate('/user');
                        break;
                    default:
                        navigate('/');
                        break;
                }

                setUsername('');
                setPassword('');
            } else {
                setError('Login failed: ' + data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An unexpected error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container"> {/* Apply the login container class */}
            <form className="login-form" onSubmit={handleSubmit}>
                <label className="login-label">
                    Username:
                    <input type="text" className="login-input" value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <label className="login-label">
                    Password:
                    <input type="password" className="login-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <button type="submit" className="login-button" disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Submit'}
                </button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default LoginPage;